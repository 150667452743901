import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import homeinterior1 from "../../assets/homeinterior/homeinterior1.jpg";
import homeinterior2 from "../../assets/homeinterior/homeinterior2.jpg";
import homeinterior3 from "../../assets/homeinterior/homeinterior3.jpg";
import homeinterior4 from "../../assets/homeinterior/homeinterior4.jpg";
import homeinterior5 from "../../assets/homeinterior/homeinterior5.jpg";
import homeinterior6 from "../../assets/homeinterior/homeinterior6.jpg";
import homeinterior7 from "../../assets/homeinterior/homeinterior7.jpg";

const projects = [
    {
        title: "Home Interior Work",
        description: "Whethere it's flooring, installation or crown moulding, Cedar Carpentry has got you covered! For example, check out this recent flooring project below we did.",
        images: [
            homeinterior1, homeinterior2, homeinterior3, homeinterior4, homeinterior5, homeinterior6, homeinterior7
        ],
    },
];

const bgimages = [
  homeinterior1
];

const galleryImages = [
  homeinterior1, homeinterior2, homeinterior3, homeinterior4, homeinterior5, homeinterior6, homeinterior7
    
];

const HomeInterior = () => {
  const [bgImage, setBgImage] = useState(bgimages[0]);
  const [bgIndex, setBgIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndices, setCurrentImageIndices] = useState(
    Array(projects.length).fill(0)
  );

  const nextImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? (index + 1) % projects[projectIndex].images.length
          : index
      )
    );
  };

  const prevImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? index === 0
            ? projects[projectIndex].images.length - 1
            : index - 1
          : index
      )
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % bgimages.length;
        setBgImage(bgimages[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const openImage = (index) => {
    setSelectedImage(index);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col min-h-screen jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${homeinterior6})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#14121F] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#dc0e0e] text-4xl font-bold mb-4 px-6">
            Home Interior Work
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+12067422047"
              className="bg-[#14121F] text-[#dc0e0e] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (409) 932-4028 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8 flex-1">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
            {projects.map((project, projectIndex) => (
              <div key={projectIndex} className="mb-12 ">
                <h2 className="text-2xl font-bold text-center mb-4">
                  {project.title}
                </h2>
                <p className="text-center text-lg mb-4">
                  {project.description}
                </p>
                <div className="relative w-full max-w-screen-lg mx-auto">
                <motion.img
                  src={project.images[currentImageIndices[projectIndex]]}
                  alt={`Project ${projectIndex} - Image ${currentImageIndices[projectIndex]}`}
                  className="w-full sm:h-96 h-auto object-cover"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                />
                <button
                  className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                  onClick={() => prevImage(projectIndex)}
                >
                  Prev
                </button>
                <button
                  className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                  onClick={() => nextImage(projectIndex)}
                >
                  Next
                </button>
              </div>
              </div>
            ))}
          </div>
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4">
        {galleryImages.map((image, index) => (
          <motion.div
            key={index}
            className="border"
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            onClick={() => openImage(index)}
          >
            <img
              src={image}
              alt={`Gallery ${index}`}
              className="w-full h-96 object-cover"
            />
          </motion.div>
        ))}
      </div>

      {selectedImage !== null && (
        <motion.div
          className="fixed inset-0 bg-[#14121F] bg-opacity-75 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          onClick={closeImage}
        >
          <motion.img
            src={galleryImages[selectedImage]}
            alt={`Gallery ${selectedImage}`}
            className="max-w-full max-h-full px-4 sm:px-3"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      )}
    </div>
  );
};

export default HomeInterior;
