import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import About from './pages/About';
import CAD from './pages/services/CAD';
import Sheds from './pages/services/Sheds';
import Decks from './pages/services/Decks';
import HomeExterior from './pages/services/HomeExteriorRepair';
import HomeInterior from './pages/services/HomeInteriorWork';
import Electrical from './pages/services/Electrical';

function App() {
  return (
    <Router>
      <div className='flex flex-col min-h-screen'>
        <Header />
        <main className='flex-grow'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/about' element={<About />} />
            <Route path='/services/cad' element={<CAD />} />
            <Route path='/services/sheds' element={<Sheds />} />
            <Route path='/services/decks' element={<Decks />} />
            <Route path='/services/home-exterior' element={< HomeExterior />} />
            <Route path='/services/home-interior' element={< HomeInterior />} />
            <Route path='/services/electrical' element={<Electrical/>} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
