import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import img1 from "../assets/img1.jpg";
import img2 from "../assets/img2.jpg";
import img3 from "../assets/img3.jpg";
import img4 from "../assets/img4.jpg";
import img5 from "../assets/img5.jpg";
import img6 from "../assets/img6.jpg";
import img7 from "../assets/img7.jpg";
import img8 from "../assets/img8.jpg";
import img9 from "../assets/img9.jpg";
import img10 from "../assets/img10.jpg";
import img11 from "../assets/img11.jpg";
import img12 from "../assets/img12.jpg";
import img13 from "../assets/img13.jpg";
import img14 from "../assets/img14.jpg";
import img15 from "../assets/img15.jpg";
import img16 from "../assets/img16.jpg";
import img17 from "../assets/img17.jpg";
import img18 from "../assets/img18.jpg";
import img19 from "../assets/img19.jpg";
import img20 from "../assets/img20.jpg";
import img21 from "../assets/img21.jpg";
import img22 from "../assets/img22.jpg";
import img23 from "../assets/img23.jpg";
import img24 from "../assets/img24.jpg";
import img25 from "../assets/img25.jpg";
import img26 from "../assets/img26.jpg";
import img27 from "../assets/img27.jpg";
import img28 from "../assets/img28.jpg";
import img29 from "../assets/img29.jpg";
import img30 from "../assets/img30.jpg";
import img31 from "../assets/img31.jpg";
import img32 from "../assets/img32.jpg";
import img33 from "../assets/img33.jpg";
import img34 from "../assets/img34.jpg";
import img35 from "../assets/img35.jpg";
import img36 from "../assets/img36.jpg";
import img37 from "../assets/img37.jpg";
import img38 from "../assets/img38.jpg";
import img39 from "../assets/img39.jpg";
import img40 from "../assets/img40.jpg";
import img41 from "../assets/img41.jpg";
import img42 from "../assets/img42.jpg";
import img43 from "../assets/img43.jpg";
import img44 from "../assets/img44.jpg";
import img45 from "../assets/img45.jpg";
import img46 from "../assets/img46.jpg";
import img47 from "../assets/img47.jpg";
import img48 from "../assets/img48.jpg";
import img49 from "../assets/img49.jpg";
import img50 from "../assets/img50.jpg";
import img51 from "../assets/img51.jpg";
import img52 from "../assets/img52.jpg";
import img53 from "../assets/img53.jpg";
import img54 from "../assets/img54.jpg";


import shed from "../assets/shed.jpg";
import fencing from "../assets/fencing.jpg";

const bgimages = [bg1, bg2, bg3];
const galleryImages = [
  bg1,
  bg2,
  bg3,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  fencing,
  shed,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
];
const projects = [
  {
    title: "Deck Design Alterations",
    description:
      "Next spring it will receive new paint! We rebuilt the entire staircase and can't wait to do more!",
    images: [img34, img35, img36, img37, img38, img39, img40, img41, img42, img43, img44, img45, img46, img47, img48],
  },
  {
    title: "Transforming a shed!",
    description:
      "The owners of the shed wanted to salvage the exisiting one, add height for overhad loft storage and increase overall sqft.",
    images: [img49, img50, img51, img52, img53, img54],
  },
];

const Gallery = () => {
  const [bgImage, setBgImage] = useState(bgimages[0]);
  const [bgIndex, setBgIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndices, setCurrentImageIndices] = useState(
    Array(projects.length).fill(0)
  );

  const nextImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? (index + 1) % projects[projectIndex].images.length
          : index
      )
    );
  };

  const prevImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? index === 0
            ? projects[projectIndex].images.length - 1
            : index - 1
          : index
      )
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % bgimages.length;
        setBgImage(bgimages[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const openImage = (index) => {
    setSelectedImage(index);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#120309] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#dc0e0e] text-4xl font-bold mb-4 px-6">
            Past Work
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+14099324028"
              className="bg-[#14121F] text-[#dc0e0e] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (409) 932-4028 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8 flex-1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {projects.map((project, projectIndex) => (
            <div key={projectIndex} className="mb-12">
              <h2 className="text-2xl font-bold text-center mb-4">
                {project.title}
              </h2>
              <p className="text-center text-lg mb-4">{project.description}</p>
              <div className="relative w-full h-[500px] overflow-hidden">
                <motion.img
                  src={project.images[currentImageIndices[projectIndex]]}
                  alt={`Project ${projectIndex} - Image ${currentImageIndices[projectIndex]}`}
                  className="w-full h-full object-cover"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                />
                <button
                  className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                  onClick={() => prevImage(projectIndex)}
                >
                  Prev
                </button>
                <button
                  className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                  onClick={() => nextImage(projectIndex)}
                >
                  Next
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container mx-auto px-4 py-8 flex-1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {galleryImages.map((image, index) => (
            <motion.div
              key={index}
              className="border"
              whileHover={{
                scale: 1.1,
                translateZ: 50,
                perspective: 1000,
                boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              onClick={() => openImage(index)}
            >
              <img
                src={image}
                alt={`Gallery ${index}`}
                className="w-full h-[400px] object-cover"
              />
            </motion.div>
          ))}
        </div>
      </div>

      {selectedImage !== null && (
        <motion.div
          className="fixed inset-0 bg-[#120309] bg-opacity-75 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          onClick={closeImage}
        >
          <motion.img
            src={galleryImages[selectedImage]}
            alt={`Gallery ${selectedImage}`}
            className="max-w-full max-h-full px-4 sm:px-3"
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 20 }}
          />
        </motion.div>
      )}
    </div>
  );
};

export default Gallery;
