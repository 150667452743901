import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import electrical1 from "../../assets/electrical/electrical1.jpg";
import electrical2 from "../../assets/electrical/electrical2.jpg";
import electrical3 from "../../assets/electrical/electrical3.jpg";
import electrical4 from "../../assets/electrical/electrical4.jpg";
import electrical5 from "../../assets/electrical/electrical5.jpg";
import electrical6 from "../../assets/electrical/electrical6.jpg";
import electrical7 from "../../assets/electrical/electrical7.jpg";
import electrical8 from "../../assets/electrical/electrical8.jpg";
import electrical9 from "../../assets/electrical/electrical9.jpg";
import electrical10 from "../../assets/electrical/electrical10.jpg";
import electrical11 from "../../assets/electrical/electrical11.jpg";
import electrical12 from "../../assets/electrical/electrical12.jpg";
import electrical13 from "../../assets/electrical/electrical13.jpg";
import electrical14 from "../../assets/electrical/electrical14.jpg";
import electrical15 from "../../assets/electrical/electrical15.jpg";
import electrical16 from "../../assets/electrical/electrical16.jpg";
import electrical17 from "../../assets/electrical/electrical17.jpg";
import electrical18 from "../../assets/electrical/electrical18.jpg";
import bg1 from "../../assets/bg1.jpg";
import bg2 from "../../assets/bg2.jpg";

const projects = [
  {
    title: "Comprehensive Electrical Upgrades",
    description:
      "This project included several modifications to the home electrical system including balancing overloaded 20 amp circuits, complete breaker change out of all existing 50amps, 30 and 20amp circuit interrupters, the addition of 240v RV/EV charging stations, multiple garage and exterior outlets, all the while staying code compliant. All these efforts will be permanent and will pass city inspections, given the owner decides to have an electrical contractor upgrade the panel to a 200amp, in the future.",
    images: [
      electrical1,
      electrical2,
      electrical3,
      electrical4,
      electrical5,
      electrical6,
      electrical7,
      electrical8,
      electrical9,
      electrical10,
      electrical11,
      electrical12,
      electrical13,
      electrical14,
      electrical15,
      electrical16,
      electrical17,
      electrical18,
    ],
  },
];

const bgimages = [bg1, bg2];

const galleryImages = [
  electrical1,
  electrical2,
  electrical3,
  electrical4,
  electrical5,
  electrical6,
  electrical7,
  electrical8,
  electrical9,
  electrical10,
  electrical11,
  electrical12,
  electrical13,
  electrical14,
  electrical15,
  electrical16,
  electrical17,
  electrical18,
];

const Electrical = () => {
  const [bgImage, setBgImage] = useState(bgimages[0]);
  const [bgIndex, setBgIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndices, setCurrentImageIndices] = useState(
    Array(projects.length).fill(0)
  );

  const nextImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? (index + 1) % projects[projectIndex].images.length
          : index
      )
    );
  };

  const prevImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? index === 0
            ? projects[projectIndex].images.length - 1
            : index - 1
          : index
      )
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % bgimages.length;
        setBgImage(bgimages[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const openImage = (index) => {
    setSelectedImage(index);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col min-h-screen jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#14121F] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#dc0e0e] text-4xl font-bold mb-4 px-6">
            Electrical Services
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+12067422047"
              className="bg-[#14121F] text-[#dc0e0e] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (409) 932-4028 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8 flex-1">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
          {projects.map((project, projectIndex) => (
            <div key={projectIndex} className="mb-12">
              <h2 className="text-2xl font-bold text-center mb-4">
                {project.title}
              </h2>
              <p className="text-center text-lg mb-4">{project.description}</p>
              <div className="relative w-full max-w-screen-lg mx-auto">
                <motion.img
                  src={project.images[currentImageIndices[projectIndex]]}
                  alt={`Project ${projectIndex} - Image ${currentImageIndices[projectIndex]}`}
                  className="w-full sm:h-96 h-auto object-cover"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                />
                <button
                  className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                  onClick={() => prevImage(projectIndex)}
                >
                  Prev
                </button>
                <button
                  className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                  onClick={() => nextImage(projectIndex)}
                >
                  Next
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4">
        {galleryImages.map((image, index) => (
          <motion.div
            key={index}
            className="border"
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            onClick={() => openImage(index)}
          >
            <img
              src={image}
              alt={`Gallery ${index}`}
              className="w-full h-96 object-cover"
            />
          </motion.div>
        ))}
      </div>

      {selectedImage !== null && (
        <motion.div
          className="fixed inset-0 bg-[#14121F] bg-opacity-75 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          onClick={closeImage}
        >
          <motion.img
            src={galleryImages[selectedImage]}
            alt={`Gallery ${selectedImage}`}
            className="max-w-full max-h-full px-4 sm:px-3"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      )}
    </div>
  );
};

export default Electrical;
