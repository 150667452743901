import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import homeexterior1 from "../../assets/homeexteriorrepair/homeexterior1.jpg";
import homeexterior2 from "../../assets/homeexteriorrepair/homeexterior2.jpg";
import homeexterior3 from "../../assets/homeexteriorrepair/homeexterior3.jpg";
import homeexterior4 from "../../assets/homeexteriorrepair/homeexterior4.jpg";
import homeexterior5 from "../../assets/homeexteriorrepair/homeexterior5.jpg";
import homeexterior6 from "../../assets/homeexteriorrepair/homeexterior6.jpg";
import homeexterior7 from "../../assets/homeexteriorrepair/homeexterior7.jpg";
import homeexterior8 from "../../assets/homeexteriorrepair/homeexterior8.jpg";
import homeexterior9 from "../../assets/homeexteriorrepair/homeexterior9.jpg";
import homeexterior10 from "../../assets/homeexteriorrepair/homeexterior10.jpg";
import homeexterior11 from "../../assets/homeexteriorrepair/homeexterior11.jpg";
import homeexterior12 from "../../assets/homeexteriorrepair/homeexterior12.jpg";
import homeexterior13 from "../../assets/homeexteriorrepair/homeexterior13.jpg";
import homeexterior14 from "../../assets/homeexteriorrepair/homeexterior14.jpg";
import homeexterior15 from "../../assets/homeexteriorrepair/homeexterior15.jpg";

const projects = [
  {
    title: "Home Exterior Repairs",
    description:
      "With the bomb cyclone in Seattle, Cedar Carpentry stepped in to help our neighbors out with fence repairs. Whatever your home exterior needs may be, give us a call and we can help you like the hundreds of customers we already have helped.",
    images: [
      homeexterior1,
      homeexterior2,
      homeexterior3,
      homeexterior4,
      homeexterior5,
      homeexterior6,
      homeexterior7,
      homeexterior8,
      homeexterior9,
      homeexterior10,
      homeexterior11,
    ],
  },
];

const bgimages = [homeexterior2];

const galleryImages = [
  homeexterior12,
  homeexterior13,
  homeexterior14,
  homeexterior15,
  homeexterior1,
  homeexterior2,
  homeexterior3,
  homeexterior4,
  homeexterior5,
  homeexterior6,
  homeexterior7,
  homeexterior8,
  homeexterior9,
  homeexterior10,
  homeexterior11,
];

const HomeExterior = () => {
  const [bgImage, setBgImage] = useState(bgimages[0]);
  const [bgIndex, setBgIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndices, setCurrentImageIndices] = useState(
    Array(projects.length).fill(0)
  );

  const nextImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? (index + 1) % projects[projectIndex].images.length
          : index
      )
    );
  };

  const prevImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? index === 0
            ? projects[projectIndex].images.length - 1
            : index - 1
          : index
      )
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % bgimages.length;
        setBgImage(bgimages[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const openImage = (index) => {
    setSelectedImage(index);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col min-h-screen jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${homeexterior2})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#14121F] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#dc0e0e] text-4xl font-bold mb-4 px-6">
            Home Exterior Repairs
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+12067422047"
              className="bg-[#14121F] text-[#dc0e0e] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (409) 932-4028 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8 flex-1">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
          {projects.map((project, projectIndex) => (
            <div key={projectIndex} className="mb-12 ">
              <h2 className="text-2xl font-bold text-center mb-4">
                {project.title}
              </h2>
              <p className="text-center text-lg mb-4">{project.description}</p>
              <div className="relative w-full max-w-screen-lg mx-auto">
                <motion.img
                  src={project.images[currentImageIndices[projectIndex]]}
                  alt={`Project ${projectIndex} - Image ${currentImageIndices[projectIndex]}`}
                  className="w-full sm:h-96 h-auto object-cover"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                />
                <button
                  className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                  onClick={() => prevImage(projectIndex)}
                >
                  Prev
                </button>
                <button
                  className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                  onClick={() => nextImage(projectIndex)}
                >
                  Next
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4">
        {galleryImages.map((image, index) => (
          <motion.div
            key={index}
            className="border"
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            onClick={() => openImage(index)}
          >
            <img
              src={image}
              alt={`Gallery ${index}`}
              className="w-full h-96 object-cover"
            />
          </motion.div>
        ))}
      </div>

      {selectedImage !== null && (
        <motion.div
          className="fixed inset-0 bg-[#14121F] bg-opacity-75 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          onClick={closeImage}
        >
          <motion.img
            src={galleryImages[selectedImage]}
            alt={`Gallery ${selectedImage}`}
            className="max-w-full max-h-full px-4 sm:px-3"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      )}
    </div>
  );
};

export default HomeExterior;
